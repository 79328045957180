import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import RequestLoanContext from 'context/RequestLoan'
import { ROUTES } from 'routes'
import { BANKS_PR } from 'constants'

export default function PaymentProfiles() {
    const { getPaymentProfiles } = useContext(RequestLoanContext)

    const [paymentProfiles, setPaymentProfiles] = useState([])

    const init = async () => {
        const data = await getPaymentProfiles()
        setPaymentProfiles(data.data)
    }

    const getBankName = routingNumber => {
        const bank = BANKS_PR.find(bank => bank.routingNumber === routingNumber)
        return bank?.name || 'N/A'
    }

    const getAccountTitle = value => {
        return `Cuenta **** ${String(value).slice(-4)}`
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between">
                <h1 className="text-3xl text-gray-900 font-medium">Perfil de Pago</h1>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Tipo
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Número de Cuenta
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Banco
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {paymentProfiles.map((payment, index) => (
                                        <tr key={index} className="text-sm text-gray-500">
                                            <td className={`whitespace-nowrap px-3 py-4`}>{payment.account_type}</td>
                                            <td className={`whitespace-nowrap px-3 py-4`}>{getAccountTitle(payment.account_number)}</td>
                                            <td className={`whitespace-nowrap px-3 py-4`}>{getBankName(payment.routing_number)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
