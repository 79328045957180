import React, { useState } from 'react'
import http, { JWT_KEY } from 'utils/http'
import AuthContext from './'
import Cookies from 'js-cookie'
import { ROUTES } from 'routes'

const AuthState = props => {
    const initialState = {
        phone: null,
        token: null,
        phoneVerification: {
            new: true,
            completed: false
        },
        application: null,
        userStatus: null,
        steps: [
            {
                number: '01',
                title: 'Tu Negocio',
                pathname: ROUTES.BUSINESS,
                done: false
            },
            {
                number: '02',
                title: 'Información general',
                pathname: ROUTES.OWNER,
                done: false
            }
        ]
    }

    const [state, setState] = useState(() => {
        const user_tracking = JSON.parse(localStorage.getItem('user_tracking'))
        if (user_tracking) return user_tracking

        return initialState
    })

    const resetState = () => {
        setState(initialState)
        localStorage.setItem('user_tracking', JSON.stringify(initialState))
    }

    const saveState = newValues => {
        setState(newValues)
        localStorage.setItem('user_tracking', JSON.stringify(newValues))
    }

    const createAuthToken = ({ token }) => {
        Cookies.set(JWT_KEY, token, { expires: 604800 })
    }

    const sendPhone = async phone => {
        try {
            const response = await http.post('/auth/send-phone', {
                phone
            })

            setState({ ...initialState, phone })
            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const updatePhone = phone =>{
        setState({ ...initialState, phone })
    }

    const createUser = async values => {
        try {
            const { user, business, application } = values

            const response = await http.post('/users', {
                user: user,
                business: business,
                application: application
            })

            return response.data
        } catch (error) {
            return {
                success: false,
                data: error
            }
        }
    }

    const getUserStatus = async (returnMapped = true) => {
        try {
            const response = await http.get(`/user/status`)

            return response.data.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const getApplication = async () => {
        try {
            const response = await http.get(`/current-application`)
            return response.data.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const setSteps = ({ pathname }) => {
        const steps = state.steps.map(step => {
            if (step.pathname === pathname) {
                step.done = true
            }
            return step
        })

        setState({
            ...state,
            steps: steps
        })
    }

    const providerValues = {
        /** State */
        state,
        resetState,
        saveState,
        createAuthToken,
        sendPhone,
        updatePhone,
        createUser,
        getUserStatus,
        getApplication,
        setSteps
    }

    return <AuthContext.Provider value={providerValues}>{props.children}</AuthContext.Provider>
}

export default AuthState
