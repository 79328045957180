import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { PAYMENT_STATUS } from 'constants'
import { currencyFormat } from 'utils/currencyFormat'
import { ROUTES } from 'routes'

import RequestLoan from 'context/RequestLoan'

export default function Payments() {
    const { state } = useContext(RequestLoan)

    const [payments, setPayments] = useState([])

    const getRowLabelClassname = keyValue => {
        switch (keyValue) {
            case PAYMENT_STATUS.paid:
                return `bg-success-100 text-success-500 px-3 text-xs rounded-full px-2 py-1 max-w-min`
            case PAYMENT_STATUS.reversed:
                return `bg-red-100 text-red-500 px-3 text-xs rounded-full px-2 py-1 max-w-min`
            default:
                return ''
        }
    }

    const setValues = () => {
        const currentLoan = state?.loan
        const payments = currentLoan?.loanpro?.payments
        if (!payments) return
        setPayments(payments.reverse())
    }

    useEffect(() => {
        setValues()
    }, [state.loan])

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between">
                <h1 className="text-3xl text-gray-900 font-medium">Pagos</h1>
                <Link to={ROUTES.NEW_PAYMENT} className="bg-primary-500 text-white py-2 px-4 rounded-lg">
                    Realizar pago
                </Link>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Título
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Fecha
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Cantidad
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tipo
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {payments.map((payment, index) => (
                                        <tr key={index} className="text-sm text-gray-600">
                                            <td className={`whitespace-nowrap px-3 py-4`}>ID {payment.id}</td>
                                            <td className={`whitespace-nowrap px-3 py-4`}>{dayjs(payment.date).format('DD MMM, YYYY')}</td>
                                            <td className={`whitespace-nowrap px-3 py-4`}>{currencyFormat(parseInt(payment.amount))}</td>
                                            <td className={`whitespace-nowrap px-3 py-4`}>
                                                <p className={`${getRowLabelClassname(payment.status)}`}>{payment.status === 'Active' ? 'Pagado' : 'Reversado'}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
