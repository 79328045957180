import axios from 'axios'
import Cookies from 'js-cookie'

let http = axios.create({
    baseURL: process.env.REACT_APP_API
})

export const JWT_KEY = 'kiwi_capital_token'
const getAuthToken = () => Cookies.get(JWT_KEY)

const authInterceptor = config => {
    const token = getAuthToken()
    if (token) config.headers['Authorization'] = `Bearer ${getAuthToken()}`
    return config
}

http.interceptors.request.use(authInterceptor)

export default http
