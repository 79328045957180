import { Fragment, useEffect, useContext, useState } from 'react'
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom'
import { OfficeBuildingIcon, UserIcon, LogoutIcon } from '@heroicons/react/outline'
import AuthContext from 'context/Auth'
import RequestLoanContext from 'context/RequestLoan'
import { ROUTES } from 'routes'
import Sidebar from 'components/Sidebar'
import Cookies from 'js-cookie'
import { JWT_KEY } from 'utils/http'

export default function Balance() {
    const navigate = useNavigate()
    const location = useLocation()

    const [isLoading, setLoading] = useState(true)
    const { getUserStatus } = useContext(AuthContext)

    const { state } = useContext(RequestLoanContext)

    const getStatus = async () => {
        await getUserStatus(false)
        setLoading(false)
    }

    const setProperRoutes = async () => {
        if (!state?.business?.loans[0]?.loanpro_id) return navigate(ROUTES.OFFERS)
    }

    const logout = () => {
        Cookies.remove(JWT_KEY)
        window.location.reload()
    }

    useEffect(() => {
        getStatus()
        setProperRoutes()
    }, [])

    return (
        <Fragment>
            <Sidebar />
            <div className="lg:pl-72">
                <main className="py-10">
                    {isLoading ? (
                        <section className="flex-grow px-2 -mr-1 sm:px-6 lg:px-8 py-10 flex items-center justify-center">
                            <div className="mx-auto max-w-7xl w-full flex items-center justify-center">
                                <svg className="w-12 h-12 animate-spin text-primary-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </section>
                    ) : (
                        <div className="p-6 pt-0">
                            <h2 className="text-xl leading-6 font-semibold text-gray-900 mb-4">Configuración</h2>
                            <div className="flex items-start flex-col md:flex-row gap-5">
                                <div className="flex-none border rounded-md mr-6 py-2 max-w-4xl w-full md:w-auto">
                                    <ul className="flex flex-col">
                                        <li className="flex">
                                            <Link to={ROUTES.PROFILE_USER} className={`px-4 py-3 pr-28 ${location.pathname === ROUTES.PROFILE_USER ? 'text-primary-500' : ''}`}>
                                                <UserIcon className="w-5 h-5 mr-2 inline-block" />
                                                Mi Perfil
                                            </Link>
                                        </li>
                                        <li className="flex">
                                            <Link to={ROUTES.PROFILE_BUSINESS} className={`px-4 py-3 pr-28 ${location.pathname === ROUTES.PROFILE_BUSINESS ? 'text-primary-500' : ''}`}>
                                                <OfficeBuildingIcon className="w-5 h-5 mr-2 inline-block" />
                                                Mi Negocio
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="w-full border rounded-md">
                                        <Outlet />
                                    </div>
                                    {location.pathname === ROUTES.PROFILE_USER && (
                                        <div className="w-full border rounded-md mt-5">
                                            <main className="py-10">
                                                <div className="max-w-4xl m-auto">
                                                    <div className="flex flex-col bg-white px-6 sm:px-12">
                                                        <h2 className="text-gray-800 text-lg font-semibold mb-4">Salir de la cuenta</h2>
                                                    </div>
                                                    <div className="px-12 flex flex-col lg:flex-row sm:justify-between">
                                                        <p>Esta opción te permite salir de cuenta de forma segura</p>
                                                        <button onClick={logout} className="border-primary-500 mt-5 lg:mt-0 flex gap-2 px-4 py-2 border rounded-lg text-primary-500">
                                                            <LogoutIcon className="w-5 h-5" />
                                                            <p className="text-sm">Cerrar sesión</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </main>
            </div>
        </Fragment>
    )
}
