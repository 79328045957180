import React from 'react'
import { useNavigate } from 'react-router-dom'
import BaseModal from '../BaseModal'
import Button from 'components/Button'
import SuccessImg from 'assets/images/success.svg'
import RejectedImg from 'assets/images/rejected.svg'
import { ROUTES } from 'routes'

const NewPaymentStatusModal = ({ isVisible, status }) => {
    const navigate = useNavigate()

    const onSubmit = () => {
        navigate(ROUTES.PAYMENTS)
    }

    const statusText = status => {
        if (status === 'fail') {
            return {
                status: 'fail',
                text: 'Tu pago presentó un error',
                subtext: 'Contacta a soporte: lanco@kiwicapital.co'
            }
        } else {
            return {
                status: 'success',
                text: 'Tu pago fue realizado con exito',
                subtext: 'Puedes verlo en la lista de pagos'
            }
        }
    }

    return (
        <BaseModal
            noClose={true}
            title=""
            description={
                <div className="flex flex-col justify-center items-center">
                    <img src={statusText(status).status === 'fail' ? RejectedImg : SuccessImg} alt="Success" className="h-48 mb-2" />
                    <p className="text-xl font-semibold text-secondary-800 mb-1">{statusText(status).text}</p>
                    <p className="text-sm mb-4">{statusText(status).subtext}</p>
                    <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" onClick={onSubmit}>
                        Aceptar
                    </Button>
                </div>
            }
            isVisible={isVisible}
            closeModal={() => {}}></BaseModal>
    )
}

export default NewPaymentStatusModal
