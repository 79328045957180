import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestLoanContext from 'context/RequestLoan'
import HeaderApp from 'components/Header'
import SuccessImg from 'assets/images/success.svg'
import { ROUTES } from 'routes'
import { PlaidService } from 'services/plaid/plaid.service'
import BankAccountBanner from 'components/BankAccountBanner'

const EMPTY_ARRAY = 0

export default function RequestLoanSuccess() {
    const navigate = useNavigate()
    const { state } = useContext(RequestLoanContext)

    const [hasPlaid, setHasPlaid] = useState(true)

    const getCurrentPlaid = async () => {
        const response = await PlaidService.getCurrentPlaid(state.user.id)
        if (!response.success || response.data.length === EMPTY_ARRAY) return setHasPlaid(false)
        setHasPlaid(true)
    }

    const pagesValidations = () => {
        if (!state.application) {
            return
        }

        if (state.application.status === 'approved') {
            return navigate(ROUTES.OFFERS)
        }

        if (state.application.status === 'declined') {
            return navigate(ROUTES.DECLINED)
        }
    }

    useEffect(() => {
        getCurrentPlaid()
        pagesValidations()
    }, [state.application])

    return (
        <>
            <HeaderApp />
            {!hasPlaid && <BankAccountBanner hasCreated={setHasPlaid} />}
            <div className="max-w-4xl py-10 m-auto">
                <div className="flex flex-col items-center justify-center text-center bg-white p-6 sm:p-12">
                    <img src={SuccessImg} alt="Success" className="h-64 mb-6" />
                    <h1 className="text-secondary-800 font-semibold sm:text-4xl text-4xl mb-2">Tu solicitud ha sido enviada</h1>
                    <p className="max-w-2xl text-gray-700 text-lg">
                        Nos pondremos en contacto contigo en un plazo de 24 horas para
                        <br className="hidden md:block" /> brindarte una respuesta a tu solicitud. ¡Gracias por tu paciencia!
                    </p>
                </div>
            </div>
        </>
    )
}
