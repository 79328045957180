import { Fragment, useEffect, useContext, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AuthContext from 'context/Auth'
import RequestLoanContext from 'context/RequestLoan'
import { ROUTES } from 'routes'
import Sidebar from 'components/Sidebar'

export default function Balance() {
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(true)
    const { getUserStatus } = useContext(AuthContext)

    const { state } = useContext(RequestLoanContext)

    const getStatus = async () => {
        await getUserStatus(false)
        setLoading(false)
    }

    const setProperRoutes = async () => {
        if (state?.loan?.loanpro === null) {
            navigate(ROUTES.OFFERS)
        }
    }

    useEffect(() => {
        getStatus()
        setProperRoutes()
    }, [])

    return (
        <Fragment>
            <Sidebar />
            <div className="lg:pl-72">
                <main className="py-10">
                    {isLoading ? (
                        <section className="flex-grow px-2 -mr-1 sm:px-6 lg:px-8 py-10 flex items-center justify-center">
                            <div className="mx-auto max-w-7xl w-full flex items-center justify-center">
                                <svg className="w-12 h-12 animate-spin text-primary-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </section>
                    ) : (
                        <Outlet />
                    )}
                </main>
            </div>
        </Fragment>
    )
}
