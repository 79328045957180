import React, { useRef, useState, useContext } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { NumericFormat, PatternFormat } from 'react-number-format'

import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import RequestLoanContext from 'context/RequestLoan'
import AuthContext from 'context/Auth'

import Button from 'components/Button'

import { ROUTES } from 'routes'
import { LEGAL_ENTITY, BUSINESS_INDUSTRY, OPERATING_MONTHS, OPERATING_YEARS, CITIES_PR } from 'constants'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import Tooltip from 'components/Tooltip'

export default function RequestLoanBusiness() {
    const navigate = useNavigate()
    const { state: requestState, saveState } = useContext(RequestLoanContext)
    const { setSteps } = useContext(AuthContext)

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Este campo es requerido'),
        webpage: Yup.string()
            .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Ingresa una URL correcta')
            .trim(),
        address: Yup.string().required('Este campo es requerido'),
        suite: Yup.string().trim(),
        city: Yup.string().required('Este campo es requerido'),
        state: Yup.string().required('Este campo es requerido'),
        zipcode: Yup.string()
            .required('Este campo es requerido')
            .test('minZip', 'El código debe ser de 5 dígitos', val => val && val.length === 5)
            .test('zipPR', 'El código postal debe empezar con 00', val => val && val.startsWith('00')),
        legalEntity: Yup.string().required('Este campo es requerido'),
        industry: Yup.string().required('Este campo es requerido'),
        annualIncome: Yup.string().required('Este campo es requerido'),
        years: Yup.string().required('Este campo es requerido'),
        months: Yup.string().required('Este campo es requerido'),
        employees: Yup.string().required('Este campo es requerido'),
        ein: Yup.string()
            .required('Este campo es requerido')
            .test('minZip', 'El EIN debe ser de 9 dígitos', val => val && val.length === 10),
        earningsReported: Yup.string().required('Este campo es requerido')
    })

    const onSubmit = async values => {
        setLoading(true)
        setError()

        saveState({
            ...requestState,
            business: {
                ...values,
                earningsReported: values.earningsReported === 'true' ? true : false,
                completed: true
            }
        })
        setSteps({
            pathname: ROUTES.BUSINESS
        })
        navigate(ROUTES.OWNER)
    }

    const initialValues = {
        ...requestState.business,
        earningsReported: String(requestState.business.earningsReported)
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={form}>
            {({ errors, touched }) => (
                <div className="max-w-4xl py-10 m-auto">
                    <Form className="flex flex-col bg-white px-6 sm:px-12" autoComplete="off">
                        <div className="text-center mb-12">
                            <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">Cuéntanos sobre tu negocio</h1>
                        </div>
                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="name" className="inline-block text-sm mb-2">
                                    Nombre de tu negocio
                                </label>
                                <Field id="name" name="name" className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.name && touched.name ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`} disabled={loading} placeholder="Ingresa el nombre de tu negocio (ej. Tiendas Don Juan)"/>
                                <ErrorMessage name="name" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="webpage" className="inline-block text-sm mb-2">
                                    Página web tu negocio <span className="text-gray-500">(Opcional)</span>
                                </label>
                                <Field
                                    id="webpage"
                                    name="webpage"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.webpage && touched.webpage ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                    disabled={loading}
                                    placeholder="Ingresa tu página web (ej. minegocio.com)"
                                />
                                <ErrorMessage name="webpage" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="address" className="inline-block text-sm mb-2">
                                    Dirección del negocio
                                </label>
                                <Field
                                    id="address"
                                    name="address"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.address && touched.address ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                    disabled={loading}
                                    placeholder="Ingresa la dirección de tu negocio"
                                />
                                <ErrorMessage name="address" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="suite" className="inline-block text-sm mb-2">
                                    Suite/Apt/Unidad # <span className="text-gray-500">(Opcional)</span>
                                </label>
                                <Field
                                    id="suite"
                                    name="suite"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.suite && touched.suite ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 text-sm placeholder:text-sm'}`}
                                    disabled={loading}
                                    placeholder="Ingresa el tipo de dirección"
                                />
                                <ErrorMessage name="suite" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="city" className="inline-block text-sm mb-2">
                                    Ciudad
                                </label>
                                <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                    <Field
                                        as="select"
                                        id="city"
                                        name="city"
                                        placeholder="Seleccionar"
                                        className={`focus:ring-0 w-full cursor-pointer appearance-none rounded-lg outline-none py-2 px-4 border border-solid ${errors.city && touched.city ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                        disabled={loading}>
                                        <option disabled value="">
                                            Seleccionar
                                        </option>
                                        {CITIES_PR.map((value, index) => (
                                            <option key={index} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <ErrorMessage name="city" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="state" className="inline-block text-sm mb-2">
                                    Estado
                                </label>
                                <Field id="state" name="state" className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.state && touched.state ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`} disabled={true} />
                                <ErrorMessage name="state" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="zipcode" className="inline-block text-sm mb-2">
                                    Código postal
                                </label>
                                <Field id="zipcode" name="zipcode">
                                    {({ field, form: { touched, errors } }) => (
                                        <PatternFormat
                                            className={`w-full rounded-lg py-2 border px-4 ${errors.zipcode && touched.zipcode ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50 placeholder:text-sm' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400 placeholder:text-sm'}`}
                                            format="#####"
                                            placeholder="Ingresa el código postal (ej. 00000)"
                                            mask=""
                                            {...field}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="zipcode" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 mt-8">
                            <h2 className="text-gray-800 text-lg font-semibold">Información de negocio</h2>
                            <div className="grid md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="legalEntity" className="inline-block text-sm mb-2">
                                        Entidad legal del negocio
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="legalEntity"
                                            name="legalEntity"
                                            placeholder="Seleccionar"
                                            className={`focus:ring-0 appearance-none cursor-pointer w-full rounded-lg outline-none py-2 px-4 border border-solid ${
                                                errors.legalEntity && touched.legalEntity ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'
                                            }`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {LEGAL_ENTITY.map(credit => (
                                                <option key={credit} value={credit}>
                                                    {credit}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="legalEntity" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <label htmlFor="industry" className="inline-block text-sm mb-2">
                                        Industria del negocio
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="industry"
                                            name="industry"
                                            placeholder="Seleccionar"
                                            className={`focus:ring-0 appearance-none cursor-pointer w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.industry && touched.industry ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {BUSINESS_INDUSTRY.map(credit => (
                                                <option key={credit} value={credit}>
                                                    {credit}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="industry" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <label htmlFor="annualIncome" className="inline-block text-sm mb-2">
                                            Ventas brutas anuales (Declaradas)
                                        </label>
                                        <div className="relative flex justify-end md:justify-center">
                                            <QuestionMarkCircleIcon className="w-5 h-5 fill-gray-500" tooltip-hover="industry" />
                                            <Tooltip content="Aproximación de las ventas reportado en la última declaración de impuestos del negocio. Línea 1 de la forma 1120 o línea 1 del Schedule C en la Forma 1040." className="max-w-[16rem] sm:max-w-lg md:max-w-2xl" />
                                        </div>
                                    </div>

                                    <Field name="annualIncome" id="annualIncome">
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                isAllowed={values => {
                                                    const { formattedValue, floatValue } = values
                                                    return formattedValue === '' || floatValue <= 999999999999
                                                }}
                                                {...field}
                                                {...props}
                                                id="annualIncome"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                allowNegative={false}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                autoComplete="off"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.annualIncome && touched.annualIncome ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                                placeholder="Ingresa una cantidad (ej. $5,000)"
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="annualIncome" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <label htmlFor="suite" className="inline-block text-sm mb-2">
                                        ¿Cuánto lleva operando el negocio?
                                    </label>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="years"
                                                name="years"
                                                placeholder="Años"
                                                className={`focus:ring-0 cursor-pointer appearance-none w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.years && touched.years ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}>
                                                <option disabled value="">
                                                    Años
                                                </option>
                                                {[...Array(OPERATING_YEARS)].map((_value, i) => (
                                                    <option key={i} value={i}>
                                                        {i} {i === 1 ? 'año' : 'años'}
                                                    </option>
                                                ))}
                                                <option value="20+">20+ años</option>
                                            </Field>
                                        </div>

                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="months"
                                                name="months"
                                                placeholder="Meses"
                                                className={`focus:ring-0 cursor-pointer appearance-none w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.months && touched.months ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}>
                                                <option disabled value="">
                                                    Meses
                                                </option>
                                                {[...Array(OPERATING_MONTHS)].map((_value, i) => (
                                                    <option key={i} value={i}>
                                                        {i} {i === 1 ? 'mes' : 'meses'}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <ErrorMessage name="years" component="small" className="text-red-500 inline-block text-xs w-full" />
                                        <ErrorMessage name="months" component="small" className="text-red-500 inline-block text-xs w-full" />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <label htmlFor="employees" className="inline-block text-sm mb-2">
                                            Número de empleados
                                        </label>
                                        <div className="relative flex justify-end md:justify-center">
                                            <QuestionMarkCircleIcon className="w-5 h-5 fill-gray-500" tooltip-hover="employees" />
                                            <Tooltip className="max-w-[16rem] sm:max-w-2xl" content="Si no tienen empleados, por favor seleccione 1" />
                                        </div>
                                    </div>

                                    <Field id="employees" name="employees" disabled={loading}>
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                {...field}
                                                {...props}
                                                isAllowed={values => {
                                                    const { formattedValue, floatValue } = values
                                                    return formattedValue === '' || floatValue <= 9999
                                                }}
                                                id="employees"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                autoComplete="off"
                                                placeholder='Ingresa el número de empleados (ej. 10)'
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.employees && touched.employees ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="employees" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <label htmlFor="ein" className="inline-block text-sm mb-2">
                                        Número contributivo (EIN)
                                    </label>

                                    <Field id="ein" name="ein">
                                        {({ field, form: { touched, errors } }) => (
                                            <PatternFormat
                                                className={`w-full rounded-lg py-2 border px-4 ${errors.ein && touched.ein ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50 placeholder:text-sm' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400 placeholder:text-sm'}`}
                                                format="##-#######"
                                                placeholder="Ingresa el número contributivo (ej. 00-0000000)"
                                                mask=""
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="ein" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                            </div>

                            <label htmlFor="earningsReported" className="inline-block text-sm -mb-2">
                                ¿Reportó ganancias en su última declaración de impuestos?
                            </label>
                            <div className="flex flex-col">
                                <div className="flex gap-4">
                                    <div className="relative flex w-2/4">
                                        <Field disabled={loading} className="peer hidden" type="radio" id="earningsReportedOpt1" name="earningsReported" value="true" />
                                        <div
                                            className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500  after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                errors.earningsReported && touched.earningsReported ? 'border-red-500/50' : 'border-gray-400 '
                                            }`}></div>
                                        <label
                                            className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                errors.earningsReported && touched.earningsReported ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                            }`}
                                            htmlFor="earningsReportedOpt1">
                                            Si
                                        </label>
                                    </div>

                                    <div className="relative flex w-2/4">
                                        <Field disabled={loading} className="peer hidden" type="radio" id="earningsReportedOpt2" name="earningsReported" value="false" />
                                        <div
                                            className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500 after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                errors.earningsReported && touched.earningsReported ? 'border-red-500/50' : 'border-gray-400 '
                                            }`}></div>
                                        <label
                                            className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                errors.earningsReported && touched.earningsReported ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                            }`}
                                            htmlFor="earningsReportedOpt2">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <ErrorMessage name="industry" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-6 items-center justify-center mt-12">
                            {error && <p className="text-red-500 inline-block text-sm text-center w-full">{error}</p>}
                            <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                Continuar
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}
