import React, { useEffect } from 'react'
import anime from 'animejs'

import { XIcon } from '@heroicons/react/outline'

export default function BaseModal({ isVisible, closeModal, title, description, children, showDetails = true, noClose = false }) {
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden'
            fadeInAnimation()
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const fadeInAnimation = () => {
        let container = document.getElementById('container')

        container.style.overflow = 'hidden'

        anime
            .timeline({
                easing: 'easeOutExpo',
                duration: 500,
                complete() {
                    container.style.overflow = 'auto'
                }
            })
            .add({
                opacity: [0, 1],
                targets: container
            })
            .add(
                {
                    targets: '#content',
                    opacity: [0, 1],
                    translateY: ['100vh', 0]
                },
                '-=500'
            )
    }

    const onCloseModal = event => {
        if (event && (event.currentTarget === event.target || event === 'btn')) {
            closeModal()
        }
    }

    if (isVisible) {
        return (
            <div id="container" className="modal flex fixed top-0 left-0 bottom-0 right-0 z-[60] bg-secondary-800/50 overflow-auto pt-20 md:pt-0 cursor-pointer" onMouseDown={onCloseModal}>
                <div id="content" className="p-8 bg-white mx-auto my-auto w-full max-w-xl rounded-2xl cursor-default md:!h-auto">
                    {showDetails && (
                        <div className="flex flex-col items-center mb-2">
                            <div className="w-full flex items-center justify-end">
                                {!noClose && <button className="ml-4 self-start cursor-pointer text-gray-600" onClick={() => onCloseModal('btn')}>
                                    <XIcon className="h-6 w-6" />
                                </button>}
                            </div>
                            <h2 className="text-secondary-800 text-center font-semibold text-2xl">{title}</h2>
                        </div>
                    )}

                    {description && <p className="mb-10 text-center">{description}</p>}

                    {children}
                </div>
            </div>
        )
    }
}
