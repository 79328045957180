import Cookies from 'js-cookie'
import { XIcon } from "@heroicons/react/outline";
import { JWT_KEY } from 'utils/http'
import LancoLogo from 'assets/brand/logo.svg';

const Header = ({ children }) => {

    const logout = () => {
        Cookies.remove(JWT_KEY)
        window.location.href = process.env.REACT_APP_LANDING
    };

    return <nav className="bg-white border-b border-gray-200">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-20 justify-between">
                <div className="flex flex-1 items-stretch justify-between">
                    <div onClick={()=>window.location.href = process.env.REACT_APP_LANDING} className="flex flex-1 flex-shrink-0 items-center">
                        <img src={LancoLogo} className="h-10 w-auto" alt="Kiwi" />
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        { children }
                    </div>
                    <div className="flex-1 absolute inset-y-0 right-0 flex items-center justify-end sm:static sm:inset-auto sm:ml-6 cursor-pointer" onClick={logout}>
                        <XIcon className="w-6 h-6" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
}

export default Header;