import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/outline'

import notFound from 'assets/images/not_found.svg'

export default function NotFound() {
    const navigate = useNavigate()

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">

            <div className="flex flex-col justify-center text-center m-auto">
                <img className="mx-auto mb-10" width="218" src={notFound} alt="Not Found" />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">¡Oops!</h1>
                <p>Parece que no podemos encontrar la página que está buscando</p>
            </div>

            <button className="bg-blue-kiwi text-white rounded-xl p-3 mt-10" onClick={() => {}}>
                Regresar
            </button>
        </div>
    )
}
