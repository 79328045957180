import React from 'react'
import { useNavigate } from 'react-router-dom'
import BaseModal from '../BaseModal'
import Button from 'components/Button'
import SuccessImg from 'assets/images/success.svg'
import { ROUTES } from 'routes'

const NewPaymentProfileSuccessModal = ({ isVisible }) => {
    const navigate = useNavigate()

    const onSubmit = () => {
        navigate(ROUTES.PAYMENT_PROFILES)
    }

    return (
        <BaseModal
            noClose={true}
            title=""
            description={
                <div className="flex flex-col justify-center items-center">
                    <img src={SuccessImg} alt="Success" className="h-48 mb-4" />
                    <p className="text-xl font-semibold text-secondary-800 mb-8">
                        Tu perfil de pago ha sido añadida <br />
                        con éxito
                    </p>
                    <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" onClick={onSubmit}>
                        Aceptar
                    </Button>
                </div>
            }
            isVisible={isVisible}
            closeModal={() => {}}></BaseModal>
    )
}

export default NewPaymentProfileSuccessModal
