import React from 'react'
import BankIcon from 'assets/icons/bank.svg'

const PaymentProfilesList = ({ value, accounts, onSelect }) => {

    const getAccountTitle = (item) => {
        return `Cuenta **** ${String(item.account_number).slice(
            -4
        )}`
    }

    return <div className="flex flex-col gap-6">
        {accounts.map((profile) => (
            <div key={profile.id} className="relative">
                <input
                    className="peer hidden"
                    type="radio"
                    id={profile.id}
                    name={profile.id}
                    onChange={() => {
                        onSelect(profile)
                    }}
                    checked={value?.id === profile.id}
                />
                <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-primary border border-solid border-gray-400-kiwi'></div>
                <label
                    htmlFor={profile.id}
                    className="min-h-[78px] flex cursor-pointer peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary rounded-lg border border-solid border-gray-200-kiwi p-4">
                    <div className="h-10 w-10 bg-primary/10 mr-4 grow-0 shrink-0 rounded-full flex">
                        <img
                            className="m-auto"
                            alt="icon_card"
                            src={BankIcon}
                        />
                    </div>
                    <div className="my-auto">
                        <div className="leading-5">
                            {getAccountTitle(profile)}
                        </div>
                        {profile.isPrimary === 1 ? (
                            <p className="text-xs text-gray-500-kiwi mt-1">
                                Método de pago principal
                            </p>
                        ) : (
                            ''
                        )}
                    </div>
                </label>
            </div>
        ))}
    </div>
}

export default PaymentProfilesList;