import React, { Fragment } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { JWT_KEY } from 'utils/http'

const RedirectRoute = () => {
    const token = Cookies.get(JWT_KEY)
    return <Fragment>{token ? <Navigate to="/prestamos" /> : <Outlet />}</Fragment>
}

export default RedirectRoute
