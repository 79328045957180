import { ErrorMessage, Field, Form, Formik } from 'formik'
import { NumericFormat } from 'react-number-format'
import React, { Fragment, useRef } from 'react'
import * as Yup from 'yup'
import { currencyFormat } from 'utils/currencyFormat'

import BaseModal from '../BaseModal'
import Button from 'components/Button'

export default function CustomOfferModal({ offerOptions, limit, value, payments = 4, isVisible, closeModal, onSubmit }) {
    const form = useRef()
    const initialValues = {
        amount: value ?? limit,
        payments
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .trim()
            .required('Este campo es requerido')
            .test('min-amount', `Escribe un monto menor o igual a $${limit}`, money => {
                if (money) {
                    let amount = Number(money.replace(',', '').replace('$ ', ''))
                    if (amount <= limit) {
                        return true
                    }
                }
            }),
        payments: Yup.string().trim().required('Este campo es requerido'),
        comission: Yup.number()
    })

    const submit = async values => {
        onSubmit({
            id: 'personalized',
            payments: Number(values.payments),
            comission: values.comission,
            value: typeof values.amount === 'string' ? Number(values?.amount?.replace(',', '').replace('$ ', '')) : values.amount
        })
        closeModal()
    }

    return (
        <BaseModal
            title="Personalizar Oferta"
            description={
                <Fragment>
                    Tienes un límite de <b>{currencyFormat(limit)}</b> en tu oferta
                </Fragment>
            }
            isVisible={isVisible}
            closeModal={closeModal}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit} innerRef={form}>
                {({ errors, touched, values }) => {
                    const getFormattedAmount = () => {
                        if (values && typeof values.amount === 'string') {
                            return Number(values?.amount?.replace(',', '').replace('$ ', ''))
                        }
                        return values?.amount ?? 0
                    }

                    const getLoanCommission = () => {
                        const comission = offerOptions.filter(offer => offer?.payments === Number(values?.payments))
                        values.comission = comission[0].comission ?? 0
                        return (comission[0].comission / 100) * getFormattedAmount() ?? 0
                    }

                    return (
                        <div className="max-w-4xl m-auto">
                            <Form className="flex flex-col" autoComplete="off">
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label htmlFor="amount" className="inline-block text-sm mb-2">
                                            Personaliza tu oferta
                                        </label>

                                        <Field name="amount" id="amount">
                                            {({ field, ...props }) => (
                                                <NumericFormat
                                                    {...field}
                                                    {...props}
                                                    isAllowed={values => {
                                                        const { formattedValue, floatValue } = values
                                                        return formattedValue === '' || floatValue <= limit
                                                    }}
                                                    id="amount"
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    autoComplete="off"
                                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.amount && touched.amount ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="amount" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="payments" className="inline-block text-sm mb-2">
                                            Plazos de pago
                                        </label>
                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="payments"
                                                name="payments"
                                                placeholder="Seleccionar"
                                                className={`focus:ring-0 w-full appearance-none cursor-pointer rounded-lg outline-none py-2 px-4 border border-solid ${
                                                    errors.payments && touched.payments ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'
                                                }`}>
                                                <option disabled value="">
                                                    Seleccionar
                                                </option>
                                                {offerOptions.map(credit => (
                                                    <option key={credit.payments} value={credit.payments}>
                                                        {credit.payments} meses
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <ErrorMessage name="payments" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                </div>

                                <ul className="mt-6 p-6 flex flex-col gap-4">
                                    <li className="flex gap-2 justify-between text-gray-500">
                                        Importe del crédito <span className="text-gray-900">{currencyFormat(getFormattedAmount())}</span>
                                    </li>
                                    <li className="flex gap-2 justify-between text-gray-500">
                                        Comisión por crédito <span className="text-gray-900">{currencyFormat(getLoanCommission())}</span>
                                    </li>
                                    <li className="flex gap-2 justify-between border-t pt-4 text-gray-500">
                                        Importe total adeudado <span className="text-gray-900">{currencyFormat(getFormattedAmount() + getLoanCommission())}</span>
                                    </li>
                                </ul>

                                <div className="mt-8 flex items-center justify-center gap-3">
                                    <Button className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" onClick={closeModal}>
                                        Cancelar
                                    </Button>
                                    <Button className="bg-primary-500 text-white rounded-lg px-8" type="submit">
                                        Aceptar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </BaseModal>
    )
}
