import React, { useRef, useState, useContext, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik, setFieldValue } from 'formik'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import RequestLoanContext from 'context/RequestLoan'
import AuthContext from 'context/Auth'

import { ROUTES } from 'routes'
import { LEGAL_ENTITY, BUSINESS_INDUSTRY, OPERATING_MONTHS, OPERATING_YEARS, CITIES_PR } from 'constants'

export default function RequestLoanBusiness() {
    const { state: requestState } = useContext(RequestLoanContext)

    const form = useRef()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Este campo es requerido'),
        webpage: Yup.string().trim().required('Este campo es requerido'),
        address: Yup.string().required('Este campo es requerido'),
        suite: Yup.string().trim(),
        city: Yup.string().required('Este campo es requerido'),
        state: Yup.string().required('Este campo es requerido'),
        zipcode: Yup.string()
            .required('Este campo es requerido')
            .test('minZip', 'El código debe ser de 5 dígitos', val => val && val.length === 5)
            .test('zipPR', 'El código postal debe empezar con 00', val => val && val.startsWith('00')),
        legalEntity: Yup.string().required('Este campo es requerido'),
        industry: Yup.string().required('Este campo es requerido'),
        annual_income: Yup.string().required('Este campo es requerido'),
        years: Yup.string().required('Este campo es requerido'),
        months: Yup.string().required('Este campo es requerido'),
        employees: Yup.string().required('Este campo es requerido'),
        ein: Yup.string().required('Este campo es requerido'),
        earnings_reported: Yup.string().required('Este campo es requerido')
    })

    const initialValues = {
        ...requestState.business,
        earnings_reported: String(requestState.business.earnings_reported)
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}} innerRef={form}>
            {({ errors, touched }) => (
                <div className="max-w-4xl py-10 m-auto">
                    <Form className="flex flex-col bg-white px-6 sm:px-12" autoComplete="off">
                        <h2 className="text-gray-800 text-lg font-semibold mb-4">Información general</h2>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="name" className="inline-block text-sm">
                                    Nombre de tu negocio
                                </label>
                                <Field id="name" name="name" className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.name && touched.name ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`} disabled={true} />
                                <ErrorMessage name="name" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="webpage" className="inline-block text-sm">
                                    Página web tu negocio
                                </label>
                                <Field
                                    id="webpage"
                                    name="webpage"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.webpage && touched.webpage ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                    disabled={loading}
                                />
                                <ErrorMessage name="webpage" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="address" className="inline-block text-sm">
                                    Dirección del negocio
                                </label>
                                <Field
                                    id="address"
                                    name="address"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.address && touched.address ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                    disabled={loading}
                                />
                                <ErrorMessage name="address" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="suite" className="inline-block text-sm">
                                    Suite/Apt/Unidad # <span className="text-gray-500">(Opcional)</span>
                                </label>
                                <Field
                                    id="suite"
                                    name="suite"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.suite && touched.suite ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                    disabled={loading}
                                />
                                <ErrorMessage name="suite" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="city" className="inline-block text-sm">
                                    Ciudad
                                </label>
                                <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                    <Field
                                        as="select"
                                        id="city"
                                        name="city"
                                        placeholder="Seleccionar"
                                        className={`focus:ring-0 w-full bg-gray-50 appearance-none rounded-lg outline-none py-2 px-4 border border-solid ${errors.city && touched.city ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                        disabled={loading}>
                                        <option disabled value="">
                                            Seleccionar
                                        </option>
                                        {CITIES_PR.map((value, i) => (
                                            <option key={i} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <ErrorMessage name="city" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="state" className="inline-block text-sm">
                                    Estado
                                </label>
                                <Field
                                    id="state"
                                    name="state"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.state && touched.state ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                    disabled={loading}
                                />
                                <ErrorMessage name="state" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="zipcode" className="inline-block text-sm">
                                    Código postal
                                </label>
                                <Field id="zipcode" name="zipcode" disabled={loading}>
                                    {({ field, form: { touched, errors } }) => (
                                        <PatternFormat
                                            className={`w-full rounded-lg py-2 border px-4 ${errors.zipcode && touched.zipcode ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-gray-50 focus:border-primary-500 placeholder:text-gray-400'}`}
                                            format="#####"
                                            placeholder="00000"
                                            mask=""
                                            {...field}
                                            disabled={loading}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="zipcode" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 mt-8">
                            <h2 className="text-gray-800 text-lg font-semibold">Información de negocio</h2>
                            <div className="grid md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="legalEntity" className="inline-block text-sm">
                                        Entidad legal del negocio
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="legalEntity"
                                            name="legalEntity"
                                            placeholder="Seleccionar"
                                            className={`focus:ring-0 w-full bg-gray-50 appearance-none rounded-lg outline-none py-2 px-4 border border-solid ${
                                                errors.legalEntity && touched.legalEntity ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'
                                            }`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {LEGAL_ENTITY.map(credit => (
                                                <option key={credit} value={credit}>
                                                    {credit}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="legalEntity" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="industry" className="inline-block text-sm">
                                        Industria del negocio
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="industry"
                                            name="industry"
                                            placeholder="Seleccionar"
                                            className={`focus:ring-0 w-full bg-gray-50 appearance-none rounded-lg outline-none py-2 px-4 border border-solid ${errors.industry && touched.industry ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {BUSINESS_INDUSTRY.map(credit => (
                                                <option key={credit} value={credit}>
                                                    {credit}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="industry" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="annual_income" className="inline-block text-sm">
                                        Ventas brutas anuales (Declaradas)
                                    </label>

                                    <Field disabled={loading} name="annual_income" id="annual_income">
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                disabled={loading}
                                                {...field}
                                                {...props}
                                                id="annual_income"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                allowNegative={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                autoComplete="off"
                                                className={`focus:ring-0 w-full bg-gray-500/10 rounded-lg outline-none py-2 px-4 border border-solid ${errors.annualIncome && touched.annualIncome ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="annualIncome" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="suite" className="inline-block text-sm">
                                        ¿Cuánto lleva operando el negocio?
                                    </label>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="years"
                                                name="years"
                                                placeholder="Años"
                                                className={`focus:ring-0 w-full appearance-none bg-gray-50  rounded-lg outline-none py-2 px-4 border border-solid ${errors.years && touched.years ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}>
                                                <option disabled value="">
                                                    Años
                                                </option>
                                                {[...Array(OPERATING_YEARS)].map((_value, i) => (
                                                    <option key={i} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                                <option value="20+">20+</option>
                                            </Field>
                                            <ErrorMessage name="years" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="months"
                                                name="months"
                                                placeholder="Meses"
                                                className={`focus:ring-0 appearance-none bg-gray-50 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.months && touched.months ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}>
                                                <option disabled value="">
                                                    Meses
                                                </option>
                                                {[...Array(OPERATING_MONTHS)].map((_value, i) => (
                                                    <option key={i} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="months" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="employees" className="inline-block text-sm">
                                        Número de empleados
                                    </label>
                                    <Field
                                        id="employees"
                                        name="employees"
                                        type="number"
                                        className={`focus:ring-0 w-full rounded-lg bg-gray-50 outline-none py-2 px-4 border border-solid ${errors.employees && touched.employees ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                        disabled={loading}
                                    />
                                    <ErrorMessage name="employees" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="ein" className="inline-block text-sm">
                                        Número contributivo (EIN)
                                    </label>

                                    <Field className="bg-gray-50" id="ein" name="ein" disabled={loading}>
                                        {({ field, form: { touched, errors } }) => (
                                            <PatternFormat
                                                disabled={loading}
                                                className={`w-full rounded-lg py-2 border px-4 ${errors.ein && touched.ein ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-gray-50 focus:border-primary-500 placeholder:text-gray-400'}`}
                                                format="##-#######"
                                                placeholder="00-0000000"
                                                mask=""
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="ein" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                            </div>

                            <label htmlFor="earningsReported" className="inline-block text-sm -mb-2">
                                ¿Reportó ganancias en su última declaración de impuestos?
                            </label>
                            <div className="flex gap-4">
                                <div className="relative flex w-2/4">
                                    <Field disabled={loading} className="peer hidden" type="radio" id="earningsReportedOpt1" name="earnings_reported" value="true" />
                                    <div
                                        className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-gray-500 after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-gray-500 border border-solid -translate-y-1/2 ${
                                            errors.earningsReported && touched.earningsReported ? 'border-red-500/50' : 'border-gray-400 '
                                        }`}></div>
                                    <label
                                        className={`pl-12 peer-checked:border-gray-500 peer-checked:bg-gray-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                            errors.earningsReported && touched.earningsReported ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                        }`}
                                        htmlFor="earningsReportedOpt1">
                                        Si
                                    </label>
                                </div>

                                <div className="relative flex w-2/4">
                                    <Field disabled={loading} className="peer hidden" type="radio" id="earningsReportedOpt2" name="earnings_reported" value="false" />
                                    <div
                                        className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-gray-500 after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-gray-500 border border-solid -translate-y-1/2 ${
                                            errors.earningsReported && touched.earningsReported ? 'border-red-500/50' : 'border-gray-400 '
                                        }`}></div>
                                    <label
                                        className={`pl-12 peer-checked:border-gray-500 peer-checked:bg-gray-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                            errors.earningsReported && touched.earningsReported ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                        }`}
                                        htmlFor="earningsReportedOpt2">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}
