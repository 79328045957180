import React, { useContext, useState, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { PatternFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import LancoLogo from 'assets/brand/logo.svg'
import OpaqueCircle from 'assets/icons/opaque-circle.svg'

import Button from 'components/Button'

import AuthContext from 'context/Auth'
import { ROUTES } from 'routes'
import { UserService } from 'services/user/user.service'
import { TwilioService } from 'services/twilio/twilio.service'

const Login = () => {
    const navigate = useNavigate()
    const form = useRef()

    const { updatePhone } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)

    const initialValues = {
        phone: ''
    }

    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .required('Este campo es requerido')
            .test('minPhone', 'El teléfono debe ser de 10 dígitos', val => val && val.length === 14)
    })

    const onSubmit = async values => {
        try {
            if (loading) return
            setLoading(true)

            const {success, data} = await UserService.validateUser(values.phone)

            if(!success) throw new Error()

            updatePhone(values.phone)

            if(success && !data.new_user){
                const {data} = await TwilioService.sendPhone(values.phone)
                if(data.status !== 'pending') throw new Error()
                return navigate(ROUTES.CODE_VERIFICATION)
            }

            return navigate(ROUTES.REQUEST_LOAN)

        } catch (error) {
            const { current } = form
            current.setFieldError('phone', 'Tenemos un error, intentalo de nuevo más tarde.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-white min-h-full flex relative overflow-hidden">
            <img src={OpaqueCircle} className="absolute top-0 right-0 rotate-180" alt="img" />
            <img src={OpaqueCircle} className="absolute bottom-0 left-0" alt="img" />

            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} innerRef={form}>
                {({ errors, touched }) => (
                    <div className="max-w-md w-full relative z-10 py-10 m-auto">
                        <img src={LancoLogo} className="w-32 mb-10 mx-auto" alt="img" />

                        <Form className="flex flex-col p-12" autoComplete="off">
                            <div className="text-center mb-12">
                                <h1 className="text-gray-900 font-semibold text-2xl mb-2">Ingresa a tu cuenta</h1>
                                <p className="text-gray-500">Autenticación exclusiva de Lanco</p>
                            </div>

                            <div className="mb-8">
                                <label htmlFor="phone" className="inline-block text-sm mb-2">
                                    Ingresa tu número de celular
                                </label>
                                <Field id="phone" name="phone">
                                    {({ field, form: { touched, errors } }) => (
                                        <div className="relative">
                                            <div className={`absolute top-1/2 left-4 -translate-y-1/2 ${errors.phone && touched.phone ? 'text-red-kiwi' : ''}`}>+1</div>
                                            <PatternFormat
                                                className={`w-full rounded-lg py-2 border px-4 pl-10 ${errors.phone && touched.phone ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400'}`}
                                                format="(###) ### ####"
                                                placeholder="(000) 000 0000"
                                                mask=""
                                                {...field}
                                            />
                                        </div>
                                    )}
                                </Field>
                                <ErrorMessage name="phone" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>

                            <Button className="bg-primary-500 text-white text-sm px-4 py-2 rounded-md" type="submit" loading={loading}>
                                Iniciar sesión
                            </Button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    )
}

export default Login
