import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import RequestLoanContext from 'context/RequestLoan'
import { currencyFormat } from 'utils/currencyFormat'
import { formatDate } from 'utils/formatDateUTC'

export default function Loan() {
    const { state } = useContext(RequestLoanContext)

    const [isLoading, setLoading] = useState(true)
    const [loan, setLoan] = useState()

    const setValues = () => {
        const currentLoan = state.loan
        const total = parseFloat(currentLoan.loanpro.totalAmountIncludingFee) ?? 0
        const amountDue = parseFloat(currentLoan.loanpro.amountDue) ?? 0
        const balance = parseFloat(currentLoan.loanpro.balance) ?? 0
        const daysPastDue = parseFloat(currentLoan.loanpro.daysPastDue) ?? 0
        const nextPaymentDate = formatDate(currentLoan.loanpro.nextPaymentDate)
        const nextPaymentAmount = parseFloat(currentLoan.loanpro.nextPaymentAmount) ?? 0
        setLoan({
            amountDue,
            total,
            balance,
            daysPastDue,
            nextPaymentDate,
            nextPaymentAmount,
            payments: currentLoan.loanpro.payments.reverse()
        })
        setLoading(false)
    }

    useEffect(() => {
        setValues()
    }, [state])

    return (
        !isLoading && (
            <div>
                <div className="flex justify-between px-6">
                    <h1 className="text-3xl text-gray-900 font-medium">Resumen Crédito</h1>
                    <Link to={ROUTES.NEW_PAYMENT} className="bg-primary-500 text-white py-2 px-4 rounded-lg">
                        Realizar pago
                    </Link>
                </div>
                <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 border-y mt-6">
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                        <dt className="font-medium leading-6 text-lg text-gray-600">Monto de crédito</dt>
                        <dd className="w-full flex-none text-4xl font-bold leading-10 tracking-tight text-gray-900">{currencyFormat(loan.total)}</dd>
                    </div>
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                        <dt className="font-medium leading-6 text-lg text-gray-600">Balance</dt>
                        <dd className="w-full flex-none text-4xl font-bold leading-10 tracking-tight text-gray-900">{currencyFormat(loan.balance)}</dd>
                    </div>
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                        <dt className="font-medium leading-6 text-lg text-gray-600">Próximo monto de pago</dt>
                        <dd className="w-full flex-none text-4xl font-bold leading-10 tracking-tight text-gray-900">{currencyFormat(loan.nextPaymentAmount)}</dd>
                    </div>
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                        <dt className="font-medium leading-6 text-lg text-gray-600">Próxima fecha de pago</dt>
                        <dd className="w-full flex-none text-4xl font-bold leading-10 tracking-tight text-gray-900">{loan.nextPaymentDate}</dd>
                    </div>
                </dl>

                <div className="p-6 grid grid-cols gap-12 sm:grid-cols-2">
                    <div>
                        <h2 className="text-secondary-800 font-semibold text-lg mb-4">Datos del crédito</h2>

                        <ul className="mt-6 flex flex-col gap-4">
                            <li className="flex gap-2 justify-between text-gray-900">
                                Monto a saldar <span className="text-gray-600">{currencyFormat(loan.total)}</span>
                            </li>
                            <li className="flex gap-2 justify-between text-gray-900">
                                Días en atraso <span className="text-gray-600">{loan.daysPastDue}</span>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="text-secondary-800 font-semibold text-lg mb-4">Listado de pagos</h2>
                        <ul className="space-y-6">
                            {loan?.payments?.map((value, i) => (
                                <li key={i} className="relative flex gap-x-4">
                                    {i < loan.payments.length - 1 && (
                                        <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                                            <div className="w-px bg-gray-200"></div>
                                        </div>
                                    )}
                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                        {value.status === 'Reversed' && <XCircleIcon className="h-5 w-5 text-primary-500" />}
                                        {value.status === 'Active' && <CheckCircleIcon className="h-5 w-5 text-primary-500" />}
                                        {value.status !== 'Active' && value.status !== 'Reversed' && <div className="h-1.5 w-1.5 rounded-full bg-gray-200 ring-1 ring-gray-300"></div>}
                                    </div>
                                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span className="font-medium text-gray-900">{i + 1}° pago</span>
                                    </p>
                                    <time dateTime="2023-01-23T10:32" className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                        {dayjs(value.date).format('DD MMM, YYYY')}
                                    </time>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    )
}
