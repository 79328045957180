import http from 'utils/http'

export class OfferServices {
    static async getOffers(application_id) {
        try {
            const response = await http.get(`/offers/${application_id}`)
            if(response.data.code === 500) throw new Error()
            return {
                success: true,
                code: response.data.code,
                data: response.data.data
            }
        } catch (error) {
            return {
                success: false,
                code: 500,
                data: [],
                message: 'Error while getting offers'
            }
        }
    }
}
