export const CREDIT_USE_OPTIONS = [
    'Capital de trabajo para un negocio',
    'Capital de crecimiento',
    'Pagar tarjetas de crédito o créditos pendientes',
    'Compra de inventario',
    'Compra de equipo o maquinaria',
    'Comprar un vehículo',
    'Iniciar un nuevo negocio',
    'Capital para adquisición o Compra de Negocio',
    'Comprar una franquicia',
    'Otro'
]

export const LEGAL_ENTITY = ['Propietario Único', 'C Corporación', 'S Corporación', 'Limited Liability Company', 'Partnership']

export const BUSINESS_INDUSTRY = [
    'Servicios Administrativos',
    'Reparación de Automóviles',
    'Salón de Belleza',
    'Abastecimiento',
    'Construcción',
    'Servicios de Consultoria',
    'Entretenimiento',
    'Cuidado de Salud',
    'Informática',
    'Fabricación',
    'Servicios Profesionales',
    'Restaurantes & Bares',
    'Al por Menor',
    'Transportación',
    'Wholesale',
    'Otros'
]

export const OPERATING_MONTHS = 13

export const OPERATING_YEARS = 20
