import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { JWT_KEY } from 'utils/http';
import RequestLoanContext from 'context/RequestLoan'
import AuthContext from 'context/Auth'
import { ROUTES } from 'routes'

const DashboardMiddleware = () => {
    const navigate = useNavigate()

    const { state: requestState, saveState, resetState, getCurrentLoan } = useContext(RequestLoanContext)
    const { getUserStatus, getApplication } = useContext(AuthContext)

    const [loading, setLoading] = useState(true);

    const setProperRoutes = async () => {
        const token = Cookies.get(JWT_KEY)

        if (!token || token === undefined) {
            await resetState()
            return navigate(ROUTES.LOGIN);
        }

        const user = await getUserStatus()
        const application = await getApplication();
        const loan = await getCurrentLoan();

        saveState({
            ...requestState,
            ...user,
            offers: {
                ...requestState.offers,
                offer_amount: application?.offer_amount
            },
            application,
            loan: loan?.data
        })
        setLoading(false);

        if (application.status === 'pending') {
            return navigate(ROUTES.REVIEWING);
        }

        if (application.status === 'rejected') {
            return navigate(ROUTES.REJECTED);
        }
    }

    useEffect(() => {
        setProperRoutes()
    }, [])

    return loading === false && <Outlet />;
}

export default DashboardMiddleware
