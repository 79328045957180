import React from 'react'

const Tooltip = ({ content, className }) => {
    return (
        <div className={`${className} tooltip absolute w-max bottom-[110%] z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 opacity-100`}>
            {content}
            <div className="tooltip-arrow" data-popper-arrow="" style={{ position: 'absolute', left: '0px', transform: 'translate(59px, 0px)' }}></div>
        </div>
    )
}

export default Tooltip
