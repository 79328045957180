import { useContext } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { useLocation } from 'react-router-dom'
import AuthContext from 'context/Auth'

const Steps = () => {
    const location = useLocation()
    const { state } = useContext(AuthContext)

    return (
        <div className="">
            <nav className="mx-auto px-4 sm:px-6 lg:px-8" aria-label="Progress">
                <ol className="overflow-hidden sm:flex">
                    {state.steps.map((step, index) => (
                        <li key={index} className="relative">
                            <span className="flex items-center px-6 py-5 text-sm font-medium lg:pl-9 cursor-pointer">
                                <span className="flex-shrink-0">
                                    {step.done === true && (
                                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-500">
                                            <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                    )}
                                    {step.done === false && (
                                        <span className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${location.pathname === step.pathname ? 'border-primary-300' : 'border-gray-300'}`}>
                                            <span className={`${location.pathname === step.pathname ? 'text-primary-500' : 'text-gray-500'}`}>{step.number}</span>
                                        </span>
                                    )}
                                </span>
                                <span className="ml-4 mt-0.5">
                                    <p className={`text-sm font-medium ${location.pathname === step.pathname || step.done ? 'text-primary-500' : 'text-gray-500'}`}>{step.title}</p>
                                </span>
                            </span>

                            {index !== 0 && (
                                <div className="absolute inset-0 left-0 top-0 w-3 flex items-center" aria-hidden="true">
                                    <ChevronRightIcon className="text-gray-500 w-4 h-4 flex-none" />
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}

export default Steps
