import React, { useRef, useState, useContext, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { PatternFormat } from 'react-number-format'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import RequestLoanContext from 'context/RequestLoan'
import Button from 'components/Button'
import NewPaymentProfileSuccessModal from 'components/Modals/NewPaymentProfileSuccess'
import { ROUTES } from 'routes'
import { BANKS_PR } from 'constants'

export default function NewPaymentProfile() {
    const navigate = useNavigate()
    const { createPaymentProfile } = useContext(RequestLoanContext)
    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [bank, setBank] = useState({
        name: '',
        routingNumber: '',
        maxDigitsAccount: ''
    })

    const initialValues = {
        accountType: '',
        bank: '',
        accountNumber: '',
        confirmAccountNumber: '',
        routingNumber: ''
    }

    const validationSchema = Yup.object().shape({
        accountType: Yup.string().trim().required('Este campo es requerido'),
        bank: Yup.string().trim().required('Este campo es requerido'),
        accountNumber: Yup.string().required('Este campo es requerido'),
        confirmAccountNumber: Yup.string()
            .required('Este campo es requerido')
            .oneOf([Yup.ref('accountNumber')], 'Los números de cuenta no coinciden'),
        routingNumber: Yup.string().required('Este campo es requerido')
    })

    const onSelectBank = event => {
        let bank = BANKS_PR[event.target.value]
        setBank(bank)

        form.current.setFieldValue('bank', bank.index)
        form.current.setFieldValue('routingNumber', bank.routingNumber)
    }

    const onSubmit = async values => {
        setLoading(true)
        await createPaymentProfile({
            accountType: values.accountType,
            accountNumber: values.accountNumber,
            routingNumber: values.routingNumber
        })
        setLoading(false)
        setSuccessModal(true)
    }

    useEffect(() => {
        setSuccessModal(false)
    }, [])

    return (
        <>
            <NewPaymentProfileSuccessModal isVisible={successModal} />
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col gap-6 mb-2">
                    <h1 className="text-3xl text-gray-900 font-medium">Perfil de Pago</h1>
                    <h2 className="text-gray-900 font-medium text-xl">Agrega tu perfil de pago</h2>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={form} onSubmit={onSubmit}>
                    {({ errors, touched }) => (
                        <Form className="flex flex-col bg-white" autoComplete="off">
                            <div className="flex flex-col gap-6">
                                <div>
                                    <label htmlFor="accountType" className="inline-block text-sm mb-2">
                                        Tipo de cuenta
                                    </label>
                                    <div className="flex gap-4">
                                        <div className="relative flex w-2/4">
                                            <Field className="peer hidden" type="radio" id="accountTypeOpt1" name="accountType" value="checking" />
                                            <div
                                                className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500  after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                    errors.accountType && touched.accountType ? 'border-red-500/50' : 'border-gray-400 '
                                                }`}></div>
                                            <label
                                                className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                    errors.accountType && touched.accountType ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                                }`}
                                                htmlFor="accountTypeOpt1">
                                                Checking
                                            </label>
                                        </div>

                                        <div className="relative flex w-2/4">
                                            <Field className="peer hidden" type="radio" id="accountTypeOpt2" name="accountType" value="Ahorros" />
                                            <div
                                                className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500 after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                    errors.accountType && touched.accountType ? 'border-red-500/50' : 'border-gray-400 '
                                                }`}></div>
                                            <label
                                                className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                    errors.accountType && touched.accountType ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                                }`}
                                                htmlFor="accountTypeOpt2">
                                                Ahorros
                                            </label>
                                        </div>
                                    </div>
                                    <ErrorMessage name="accountType" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="bank" className="inline-block text-sm mb-2">
                                        Selecciona tu banco
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="bank"
                                            name="bank"
                                            placeholder="Seleccionar"
                                            onInput={onSelectBank}
                                            className={`focus:ring-0 w-full appearance-none cursor-pointer rounded-lg outline-none py-2 px-4 border border-solid ${errors.bank && touched.bank ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {BANKS_PR.map((value, i) => (
                                                <option key={i} value={i}>
                                                    {value.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="bank" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="accountNumber" className="inline-block text-sm mb-2">
                                            Número de cuenta
                                        </label>
                                        <Field
                                            id="accountNumber"
                                            name="accountNumber"
                                            placeholder="000000000"
                                            className={`w-full rounded-lg py-2 border px-4 ${
                                                errors.accountNumber && touched.accountNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400'
                                            }`}></Field>
                                        <ErrorMessage name="accountNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="confirmAccountNumber" className="inline-block text-sm mb-2">
                                            Confirmar número de cuenta
                                        </label>
                                        <Field
                                            id="confirmAccountNumber"
                                            name="confirmAccountNumber"
                                            placeholder="000000000"
                                            className={`w-full rounded-lg py-2 border px-4 ${
                                                errors.confirmAccountNumber && touched.confirmAccountNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400'
                                            }`}></Field>
                                        <ErrorMessage name="confirmAccountNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="routingNumber" className="inline-block text-sm mb-2">
                                        Número de ruta
                                    </label>
                                    <Field
                                        id="routingNumber"
                                        name="routingNumber"
                                        placeholder="123456"
                                        className={`w-full rounded-lg py-2 border px-4 ${
                                            errors.routingNumber && touched.routingNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400'
                                        }`}></Field>
                                    <ErrorMessage name="routingNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                            </div>

                            <div className="mt-12 flex items-center justify-center gap-3">
                                <Button onClick={() => navigate(ROUTES.PAYMENT_PROFILES)} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                                    Cancelar
                                </Button>
                                <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                    Aceptar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}
