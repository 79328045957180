import React, { useEffect } from 'react'

export default function LoadingModal({ isVisible, isLoading }) {
    useEffect(() => {
        if (isVisible === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    if (isVisible) {
        return (
            <div className="w-full h-full flex items-center justify-center fixed top-0 left-0 z-10 bg-gray-800/50 overflow-auto">
                {isLoading && <span className="loader"></span>}
            </div>
        )
    }
}
