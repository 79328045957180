import { ErrorMessage, Field, Form, Formik } from 'formik'
import { NumericFormat } from 'react-number-format'
import { useRef } from 'react'
import * as Yup from 'yup'

import BaseModal from '../BaseModal'
import Button from 'components/Button'

export default function CustomAmountPayment({ limit, isVisible, closeModal, onSubmit }) {
    const form = useRef()

    const initialValues = {
        amount: 0
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .trim()
            .required('Este campo es requerido')
            .test('max-amount', `Escribe un monto menor o igual a $${limit}`, money => {
                if (money) {
                    let amount = Number(money.replace(',', '').replace('$ ', ''))
                    if (amount <= limit) {
                        return true
                    }
                }
            })
            .test('min-amount', `Escribe un monto mayor a $ 5.00`, money => {
                if (money) {
                    if(limit <= 20){
                        let amount = Number(money.replace(',', '').replace('$ ', ''))
                        if (amount > 5) {
                            return true
                        }
                    } else return true
                }
            })
    })

    const submit = async values => {
        onSubmit({
            id: 'personalized',
            value: typeof values.amount === 'string' ? Number(values?.amount?.replace(',', '').replace('$ ', '')) : values.amount,
        })
        closeModal()
    }

    return (
        <BaseModal
            title="Monto personalizado"
            description='Ingresa el monto que deseas pagar'
            isVisible={isVisible}
            closeModal={closeModal}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit} innerRef={form}>
                {({ errors, touched }) => {
                    return (
                        <div className="max-w-4xl m-auto">
                            <Form className="flex flex-col" autoComplete="off">
                                <div>
                                    <label htmlFor="amount" className="inline-block text-sm mb-2">
                                        Personaliza tu oferta
                                    </label>

                                    <Field name="amount" id="amount">
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                {...field}
                                                {...props}
                                                id="amount"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                allowNegative={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                autoComplete="off"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.amount && touched.amount ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                placeholder='$ 0'
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="amount" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div className="mt-8 flex items-center justify-center gap-3">
                                    <Button className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" onClick={closeModal}>
                                        Cancelar
                                    </Button>
                                    <Button className="bg-primary-500 text-white rounded-lg px-8" type="submit">
                                        Aceptar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </BaseModal>
    )
}
