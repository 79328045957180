import React, { Fragment, useState } from 'react'

import BaseModal from '../BaseModal'
import { CheckCircleIcon } from '@heroicons/react/outline'
import Button from 'components/Button'

export default function ResendCodeModal({ cellphone, isVisible, closeModal, resendCode }) {
    const [selectedOption, setSelectedOption] = useState()

    const CircleIcon = () => <span className="min-h-[20px] min-w-[20px] bg-white rounded-full border-2 border-gray-200" />

    const OPTIONS = {
        SMS: 'sms',
        WHATSAPP: 'whatsapp'
    }

    return (
        <BaseModal
            title="Verifica tu número de teléfono"
            description={
                <Fragment>
                    Selecciona la opción para reenviar código a tu número de celular <b>{cellphone}</b>
                </Fragment>
            }
            isVisible={isVisible}
            closeModal={closeModal}>
            <div onClick={() => setSelectedOption(OPTIONS.SMS)} className={`${selectedOption === OPTIONS.SMS ? 'border-primary-500 bg-primary-100/25' : 'hover:border-primary-500 border-gray-200'} flex items-center justify-between border py-2 px-4 rounded-lg mb-2 cursor-pointer`}>
                <p>Enviar código por SMS</p>
                {selectedOption === OPTIONS.SMS ? <CheckCircleIcon className="h-6 w-6 text-primary-500" /> : <CircleIcon />}
            </div>
            <div onClick={() => setSelectedOption(OPTIONS.WHATSAPP)} className={`${selectedOption === OPTIONS.WHATSAPP ? 'border-primary-500 bg-primary-100/25' : 'hover:border-primary-500 border-gray-200'} flex items-center justify-between border py-2 px-4 rounded-lg cursor-pointer`}>
                <p>Enviar código por Whatsapp</p>
                {selectedOption === OPTIONS.WHATSAPP ? <CheckCircleIcon className="h-6 w-6 text-primary-500" /> : <CircleIcon />}
            </div>

            <div className="mt-8 flex items-center justify-center gap-3">
                <Button className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" onClick={closeModal}>
                    Cancelar
                </Button>
                <Button className="bg-primary-500 text-white rounded-lg px-8" onClick={() => resendCode(selectedOption)}>
                    Reenviar
                </Button>
            </div>
        </BaseModal>
    )
}
