import http from 'utils/http'

export class PlaidService {
    static async getCurrentPlaid(user_id) {
        try {
            const response = await http.get(`/plaid/user/${user_id}`)
            return {
                data: response.data.data,
                code: response.status,
                success: !response.data.success ? false : true
            }
        } catch (error) {
            return {
                success: false,
                data: error?.response?.data.data,
                messages: error.response.data.messages,
                code: error.response.data.code
            }
        }
    }
}
