export const PAYMENT_STATUS_KEY = 'status'
export const PAYMENT_STATUS = {
    paid: 'Active',
    reversed: 'Reversed'
}

export const BANKS_PR = [
    {
        name: 'Banco Popular',
        routingNumber: '021502011',
        maxDigitsAccount: 9
    },
    {
        name: 'Oriental',
        routingNumber: '221571415',
        maxDigitsAccount: 10
    },
    {
        name: 'Firstbank',
        routingNumber: '221571473',
        maxDigitsAccount: 10
    },
    {
        name: 'Santander',
        routingNumber: '021502341',
        maxDigitsAccount: 10
    },
    {
        name: 'Otro',
        routingNumber: '',
        maxDigitsAccount: ''
    }
]
