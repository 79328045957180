import http from "utils/http"

export class TwilioService {
    static async sendPhone(phone){
        try {
            const response = await http.post('/auth/send-phone', {
                phone
            })
            return response.data
        } catch (error) {
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }
    static async validateCode (phone, code) {
        try {
            const response = await http.post('/auth/validate-code', {
                phone,
                code
            })
            return {
                success: true,
                code: response.code,
                data: response.data.data
            }
        } catch (error) {
            return {
                success: false,
                data: error.response
            }
        }
    }
}