import React, { useContext } from 'react'
import RequestLoanContext from 'context/RequestLoan'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes'

const UserTab = () => {
    const { state } = useContext(RequestLoanContext)

    return (
        <Link to={ROUTES.PROFILE_USER} className="flex items-center w-full gap-4">
            <div className="h-8 min-w-[2rem] w-8 rounded-full bg-primary-500 flex items-center justify-center text-white uppercase">
                <span>{state.user.name[0]}</span>
            </div>

            <p className="text-sm font-semibold leading-6 text-gray-900 capitalize truncate" aria-hidden="true">
                {state.business.name}
            </p>
        </Link>
    )
}

export default UserTab
