import { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CreditCardIcon, CurrencyDollarIcon, MenuIcon, ViewGridIcon, XIcon, AtSymbolIcon } from '@heroicons/react/outline'
import LancoLogo from 'assets/brand/logo.svg'
import UserTab from 'components/UserTab'
import { ROUTES } from 'routes'

const Sidebar = () => {
    const location = useLocation()

    const [sidebarStatus, setSidebarStatus] = useState(false)

    const SIDEBAR_ROUTES = [
        {
            label: 'Resumen Crédito',
            url: ROUTES.BALANCE,
            icon: ViewGridIcon,
            routes: [ROUTES.BALANCE]
        },
        {
            label: 'Pagos',
            url: ROUTES.PAYMENTS,
            icon: CurrencyDollarIcon,
            routes: [ROUTES.PAYMENTS, ROUTES.NEW_PAYMENT]
        },
        {
            label: 'Perfil de Pago',
            url: ROUTES.PAYMENT_PROFILES,
            icon: CreditCardIcon,
            routes: [ROUTES.PAYMENT_PROFILES, ROUTES.NEW_PAYMENT_PROFILE]
        }
    ]

    const close = () => {
        setSidebarStatus(false)
    }

    const open = () => {
        setSidebarStatus(true)
    }

    useEffect(() => {
        close()
    }, [location.pathname])

    return (
        <Fragment>
            <div className="w-full border-b lg:hidden">
                <button className="p-4" onClick={open}>
                    <MenuIcon className="w-5 h-5" />
                </button>
            </div>
            {/* Mobile Sidebar */}
            {sidebarStatus ? (
                <div id="sidebar" className="absolute top-0 z-50 w-full h-full flex lg:hidden">
                    <div id="sidebar-layer" className="fixed inset-0 bg-gray-900/80"></div>
                    <div id="sidebar-panel" className="fixed inset-0 flex">
                        <div className="relative mr-16 flex w-full max-w-xs flex-1">
                            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button type="button" className="-m-2.5 p-2.5" onClick={close}>
                                    <span className="sr-only">Close sidebar</span>
                                    <XIcon className="h-6 w-6 text-white" />
                                </button>
                            </div>

                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 w-full">
                                <div className="flex h-16 shrink-0 items-center">
                                    <img src={LancoLogo} className="h-10 w-auto" alt="Lanco" />
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul className="space-y-1">
                                        {SIDEBAR_ROUTES.map((route, index) => (
                                            <li key={index}>
                                                <Link to={route.url} className={`flex gap-x-3 rounded-md p-2 text-sm leading-6 group ${route.routes.includes(location.pathname) ? 'text-primary-500 bg-gray-50' : 'text-gray-600 hover:text-primary-500 hover:bg-gray-50'}`}>
                                                    <route.icon className="h-6 w-6" />
                                                    {route.label}
                                                </Link>
                                            </li>
                                        ))}
                                        <li>
                                            <Link to='mailto:lanco@kiwicapital.co' target='_blank' className='flex gap-x-3 rounded-md p-2 text-sm leading-6 group text-gray-600 hover:text-primary-500 hover:bg-gray-50'>
                                                <AtSymbolIcon className="h-6 w-6" />
                                                Soporte
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                <UserTab />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/* Desktop fixed Sidebar */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 w-full">
                    <div className="flex h-16 shrink-0 items-center">
                        <img src={LancoLogo} className="h-10 w-auto" alt="Kiwi" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="-mx-2 space-y-1">
                            {SIDEBAR_ROUTES.map((route, index) => (
                                <li key={index}>
                                    <Link to={route.url} className={`flex gap-x-3 rounded-md p-2 text-sm leading-6 group ${route.routes.includes(location.pathname) ? 'text-primary-500 bg-gray-50' : 'text-gray-600 hover:text-primary-500 hover:bg-gray-50'}`}>
                                        <route.icon className="h-6 w-6" />
                                        {route.label}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <Link to='mailto:lanco@kiwicapital.co' target='_blank' className='flex gap-x-3 rounded-md p-2 text-sm leading-6 group text-gray-600 hover:text-primary-500 hover:bg-gray-50'>
                                    <AtSymbolIcon className="h-6 w-6" />
                                    Soporte
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <UserTab />
                </div>
            </div>
        </Fragment>
    )
}

export default Sidebar
