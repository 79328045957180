import { useContext } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { PatternFormat } from 'react-number-format'
import * as Yup from 'yup'
import RequestLoanContext from 'context/RequestLoan'
import { CITIES_PR } from 'constants'
import { formatDate } from 'utils/formatDateUTC'


export default function RequestLoanOwner() {
    const { state: requestState } = useContext(RequestLoanContext)
    const owner ={
        name: requestState.user.name,
        surname: requestState.user.surname,
        email: requestState.user.email,
        birthdate: formatDate(requestState.user.birthdate),
        ssn: requestState.user.ssn,
        address: requestState.user.address,
        suite: requestState.user.suite,
        city: requestState.user.city,
        state: requestState.user.state,
        zipcode: requestState.user.zipcode,
    }
    const loading = true
    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Este campo es requerido'),
        surname: Yup.string().trim().required('Este campo es requerido'),
        email: Yup.string().required('Este campo es requerido'),
        birthdate: Yup.string().required('Este campo es requerido'),
        ssn: Yup.string().required('Este campo es requerido'),
        address: Yup.string().required('Este campo es requerido'),
        city: Yup.string().required('Este campo es requerido'),
        state: Yup.string().required('Este campo es requerido'),
        zipcode: Yup.string()
            .required('Este campo es requerido')
            .test('minZip', 'El código debe ser de 5 dígitos', val => val && val.length === 5)
            .test('zipPR', 'El código postal debe empezar con 00', val => val && val.startsWith('00')),
        terms_and_conditions: Yup.boolean().required('The terms and conditions must be accepted.').oneOf([true], 'The terms and conditions must be accepted.')
    })

    return (
        <>
            <div className="">
                <main className="py-10">
                    <Formik initialValues={owner} validationSchema={validationSchema} onSubmit={() => {}}>
                        {({ errors, touched }) => (
                            <div className="max-w-4xl m-auto">
                                <Form className="flex flex-col bg-white px-6 sm:px-12" autoComplete="off">
                                    <h2 className="text-gray-800 text-lg font-semibold mb-4">Información básica</h2>
                                    <div className="grid md:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="name" className="inline-block text-sm">
                                                Nombre
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.name && touched.name ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}
                                            />
                                            <ErrorMessage name="name" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                        <div>
                                            <label htmlFor="surname" className="inline-block text-sm">
                                                Apellido
                                            </label>
                                            <Field
                                                id="surname"
                                                name="surname"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.surname && touched.surname ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}
                                            />
                                            <ErrorMessage name="surname" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="inline-block text-sm">
                                                Correo electrónico
                                            </label>
                                            <Field
                                                id="email"
                                                name="email"
                                                type="email"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.email && touched.email ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}
                                            />
                                            <ErrorMessage name="email" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                        <div>
                                            <label htmlFor="birthdate" className="inline-block text-sm">
                                                Fecha de nacimiento
                                            </label>
                                            <Field
                                                id="birthdate"
                                                name="birthdate"
                                                type="text"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.birthdate && touched.birthdate ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}
                                            />
                                            <ErrorMessage name="birthdate" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                        <div>
                                            <label htmlFor="ssn" className="inline-block text-sm">
                                                Número de seguridad social
                                            </label>
                                            <Field id="ssn" name="ssn" disabled={loading}>
                                                {({ field, form: { touched, errors } }) => (
                                                    <PatternFormat
                                                        disabled={loading}
                                                        className={`w-full rounded-lg py-2 border px-4 ${errors.ssn && touched.ssn ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 focus:border-primary-500 placeholder:text-gray-400'}`}
                                                        format="###-##-####"
                                                        placeholder="000-00-0000"
                                                        mask=""
                                                        {...field}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="ssn" component="small" className="text-red-500 inline-block text-s w-full" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 mt-8">
                                        <h2 className="text-gray-800 text-lg font-semibold">¿Dónde vives?</h2>

                                        <div className="grid md:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="address" className="inline-block text-sm">
                                                    Dirección de tu residencia
                                                </label>
                                                <Field
                                                    id="address"
                                                    name="address"
                                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.address && touched.address ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                    disabled={loading}
                                                />
                                                <ErrorMessage name="address" component="small" className="text-red-500 inline-block text-s w-full" />
                                            </div>
                                            <div>
                                                <label htmlFor="suite" className="inline-block text-sm">
                                                    Suite/Apt/Unidad # <span className="text-gray-500">(Opcional)</span>
                                                </label>
                                                <Field
                                                    id="suite"
                                                    name="suite"
                                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.suite && touched.suite ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                    disabled={loading}
                                                />
                                                <ErrorMessage name="suite" component="small" className="text-red-500 inline-block text-s w-full" />
                                            </div>
                                            <div>
                                                <label htmlFor="city" className="inline-block text-sm">
                                                    Ciudad
                                                </label>
                                                <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                                    <Field
                                                        as="select"
                                                        id="city"
                                                        name="city"
                                                        placeholder="Seleccionar"
                                                        className={`focus:ring-0 w-full appearance-none bg-gray-50 rounded-lg outline-none py-2 px-4 border border-solid ${errors.city && touched.city ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                        disabled={loading}>
                                                        <option disabled value="">
                                                            Seleccionar
                                                        </option>
                                                        {CITIES_PR.map((value, i) => (
                                                            <option key={i} value={value.name}>
                                                                {value.name}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="city" component="small" className="text-red-500 inline-block text-s w-full" />
                                            </div>
                                            <div>
                                                <label htmlFor="state" className="inline-block text-sm">
                                                    Estado
                                                </label>
                                                <Field
                                                    id="state"
                                                    name="state"
                                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.state && touched.state ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                    disabled={loading}
                                                />
                                                <ErrorMessage name="state" component="small" className="text-red-500 inline-block text-s w-full" />
                                            </div>

                                            <div>
                                                <label htmlFor="zipcode" className="inline-block text-sm">
                                                    Código postal
                                                </label>
                                                <Field id="zipcode" name="zipcode" disabled={loading}>
                                                    {({ field, form: { touched, errors } }) => (
                                                        <PatternFormat
                                                            disabled={loading}
                                                            className={`w-full rounded-lg py-2 border px-4 ${errors.zipcode && touched.zipcode ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200  focus:border-primary-500 placeholder:text-gray-400'}`}
                                                            format="#####"
                                                            placeholder="00000"
                                                            mask=""
                                                            {...field}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="zipcode" component="small" className="text-red-500 inline-block text-s w-full" />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </main>
            </div>
        </>
    )
}
