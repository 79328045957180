import React, { useContext, useEffect, useState } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import Cookies from 'js-cookie'
import RequestLoanContext from 'context/RequestLoan'
import AuthContext from 'context/Auth'
import { JWT_KEY } from 'utils/http'
import { ROUTES } from 'routes'
import HeaderApp from 'components/Header'
import Steps from 'components/Onboarding/Steps'

export default function RequestLoan() {
    const location = useLocation()

    const { state: requestState, saveState } = useContext(RequestLoanContext)
    const { getUserStatus } = useContext(AuthContext)

    const [isLoading, setLoading] = useState(true)

    const setProperRoutes = async () => {
        const token = Cookies.get(JWT_KEY)

        if ((!token || token === undefined) && location.pathname !== ROUTES.CODE_VERIFICATION) {
            // TO DO
        } else {
            // If the user is authenticated, then get all of its information and redirect to the first page after authorization
            const user = await getUserStatus()
            saveState({
                ...requestState,
                ...user.data
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        setProperRoutes()
    }, [])

    return (
        <main className="w-full min-h-full flex flex-col">
            <HeaderApp>
                <Steps />
            </HeaderApp>
            {isLoading ? (
                <section className="flex-grow px-2 sm:px-6 lg:px-8 py-10 flex items-center justify-center">
                    <div className="mx-auto max-w-7xl w-full flex items-center justify-center">
                        <svg className="w-12 h-12 animate-spin text-primary-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                </section>
            ) : (
                <section className="flex-grow px-2 sm:px-6 lg:px-8 py-10 flex items-center justify-center">
                    <div className="mx-auto max-w-7xl w-full ">
                        <Outlet />
                    </div>
                </section>
            )}
        </main>
    )
}
