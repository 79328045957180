import React, { useState } from 'react'
import RequestLoanContext from './'
import http from 'utils/http'

const RequestLoanState = props => {
    const initialState = {
        user: {},
        amount: {
            amount: '',
            reason: '',
            phone: '',
            completed: false
        },
        business: {
            name: '',
            webpage: '',
            address: '',
            suite: '',
            city: '',
            state: 'Puerto Rico',
            zipcode: '',
            legalEntity: '',
            industry: '',
            annualIncome: '',
            years: '',
            months: '',
            employees: '',
            ein: '',
            earningsReported: '',
            completed: false
        },
        owner: {
            name: '',
            surname: '',
            email: '',
            birthdate: '',
            ssn: '',
            address: '',
            suite: '',
            city: '',
            state: 'Puerto Rico',
            zipcode: '',
            terms_and_conditions: false
        },
        reviewing: {
            application_id: null,
            completed: false
        },
        offers: {
            loan_id: null,
            completed: false
        },
        paymentProfile: {
            accountType: '',
            bank: '',
            accountNumber: '',
            confirmAccountNumber: '',
            routingNumber: '',
            completed: false
        },
        reviewingOffer: {
            completed: false
        }
    }

    const [state, setState] = useState(() => {
        return initialState
    })

    const resetState = () => {
        setState(initialState)
    }
    
    const saveState = newValues => {
        sessionStorage.setItem('loan_tracking', JSON.stringify(newValues))
        setState(newValues)
    }

    const createBusiness = async business => {
        try {
            const { ...formattedBusiness } = business
            const response = await http.post('/business', {
                business: {
                    ...formattedBusiness,
                    phoneNumber: state.amount.phone
                }
            })

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const createLoan = async (amount, comission, payments) => {
        try {
            const response = await http.post('/loans', {
                amount,
                commission: comission,
                months: Number(payments),
                business_id: state.business.id
            })

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const updateLoan = async ({ id, payload }) => {
        try {
            const response = await http.put(`/loans/${id}`, {
                ...payload
            })

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const getPaymentProfiles = async () => {
        try {
            const response = await http.get('/payment-profiles')

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const createPaymentProfile = async ({ accountType, accountNumber, routingNumber }) => {
        try {
            const response = await http.post('/payment-profiles', {
                account_type: accountType,
                account_number: accountNumber,
                routing_number: routingNumber
            })

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const getApplication = async application_id => {
        try {
            const response = await http.get(`/applications/${application_id}`)

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const getLoan = async loan_id => {
        try {
            const response = await http.get(`/loans/${loan_id}`)

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const getCurrentLoan = async () => {
        try {
            const response = await http.get(`/loans/current`)

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const createPayment = async ({ paymentProfileId, amount }) => {
        try {
            const response = await http.post(`/payments`, {
                loanId: state.loan.id,
                amount: amount,
                payment_profile_id: paymentProfileId
            })

            return response.data
        } catch (error) {
            console.log(error)
            return {
                success: false,
                data: error?.response?.data?.data
            }
        }
    }

    const providerValues = {
        /** State */
        state,
        resetState,
        saveState,
        createBusiness,
        createLoan,
        updateLoan,
        getApplication,
        getPaymentProfiles,
        createPaymentProfile,
        getLoan,
        getCurrentLoan,
        createPayment
    }

    return <RequestLoanContext.Provider value={providerValues}>{props.children}</RequestLoanContext.Provider>
}

export default RequestLoanState
