export const OFFER_OPTIONS = [
    {
        id: 2,
        payments: 4,
        comission: 10
    },
    {
        id: 3,
        payments: 6,
        comission: 15
    },
    {
        id: 4,
        payments: 9,
        comission: 20
    }
]

export const CUSTOM_OFFER_PAYMENTS_OPTIONS = [4, 6, 9]
