import React, { useEffect, useRef, useState, useContext } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import RequestLoanContext from 'context/RequestLoan'
import Button from 'components/Button'
import { ROUTES } from 'routes'
import NewPaymentStatusModal from 'components/Modals/NewPaymentStatus'
import { currencyFormat } from 'utils/currencyFormat'
import CustomAmountPayment from 'components/Modals/CustomAmountPayment'
import { formatDate } from 'utils/formatDateUTC'

export default function NewPayment() {
    const initialValues = {
        paymentProfileId: ''
    }
    const navigate = useNavigate()
    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [paymentProfiles, setPaymentProfiles] = useState([])
    const [modalStatusVisible, setModalStatusVisible] = useState(false)
    const [modalCustomAmount, setModalCustomAmount] = useState(false)
    const [status, setStatus] = useState('')
    const { getPaymentProfiles, createPayment, state } = useContext(RequestLoanContext)

    const { loan: { loanpro }} = state
    const amountPayments = [
        {
            id: 'monthlyPayment',
            value: loanpro.nextPaymentAmount,
            title: 'Pagar cuota',
            description: 'Tu cuota del mes'
        },
        {
            id: 'balance',
            value: loanpro.balance,
            title: 'Pagar balance total',
            description: 'Saldo completo'
        },
        {
            id: 'personalized',
            value: null,
            title: 'Monto personalizado',
            description: 'Elige un monto personalizado '
        },
    ]
    const [selectedAmountPayments, setSelectedAmountPayments] = useState({})

    const validationSchema = Yup.object().shape({
        paymentProfileId: Yup.string().required('Este campo es requerido')
    })

    const init = async () => {
        const data = await getPaymentProfiles()
        setPaymentProfiles(data.data)
        form.current.setValues({
            paymentProfileId: data.data[0].account_number
        })
    }

    const selectAmountPayment = async (optionPayment) => {
        if(optionPayment.id !== 'personalized'){
            return setSelectedAmountPayments(optionPayment)
        }
        setModalCustomAmount(true)
    }

    const onSubmit = async values => {
        setLoading(true)
        const response = await createPayment({
            amount: Number(values.amount),
            paymentProfileId: values.paymentProfileId
        })

        setModalStatusVisible(true)
        if (response.success) {
            setStatus('success')
        } else {
            setStatus('fail')
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-6">
            <NewPaymentStatusModal isVisible={modalStatusVisible} status={status} />
            <CustomAmountPayment isVisible={modalCustomAmount} closeModal={setModalCustomAmount} limit={loanpro.balance} onSubmit={setSelectedAmountPayments} />
            <div className="flex flex-col gap-6 mb-2">
                <h1 className="text-3xl text-gray-900 font-medium">Pagos</h1>
                <h2 className="text-gray-900 font-medium text-xl">Pago a realizar</h2>
            </div>
            <div className='grid grid-cols-2 gap-9'>
                <div className='flex flex-col gap-2'>
                    <label className='text-sm'>Fecha límite de pago</label>
                    <p className='text-gray-800'>{formatDate(loanpro.nextPaymentDate)}</p>
                </div>
{/*                 <div className='flex flex-col gap-2'>
                    <label className='text-sm'>Pagos restantes</label>
                    <p className='text-gray-800'>{loanpro.term - loanpro?.payments?.filter(payment => payment.status === 'approved').length} pagos</p>
                </div> */}
            </div>
            <div className='flex flex-col w-full gap-4'>
                <label className='font-semibold text-secondary '>¿Cuánto quieres pagar?</label>
                <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-6 '>
                    {amountPayments.map(( typePayment , index) => (
                        <button key={index} onClick={()=>selectAmountPayment(typePayment)} disabled={loanpro.balance <= 20 && typePayment.id === 'personalized'}  className={`border py-6 px-5 rounded-lg text-left text-xs ${selectedAmountPayments.id === typePayment.id ? 'border-primary-500 bg-primary-100' : 'hover:border-primary-500'} ${loanpro.balance <= 20 && typePayment.id === 'personalized' ? 'grayscale opacity-60 cursor-not-allowed' : undefined}`}>
                        <div className="flex justify-between">
                            <h3 className="font-bold text-xl">{typePayment.id !== 'personalized' ? currencyFormat(typePayment.value) : (selectedAmountPayments.id === 'personalized' && selectedAmountPayments.value ? currencyFormat(selectedAmountPayments.value) : typePayment.title)}</h3>
                            {selectedAmountPayments.id === 'personalized' && typePayment.id === 'personalized'  && (selectedAmountPayments.value || selectedAmountPayments.value > 0 ) && <span className="bg-primary-500 text-white px-4 py-1 rounded-2xl items-center flex max-h-min h-full">Personalizar</span>}
                        </div>
                        {typePayment.id !== 'personalized' && <h4 className='font-bold text-sm mt-3'>{typePayment.title}</h4>}
                        {<p className={typePayment.id === 'personalized' ? 'mt-3' : undefined}>{typePayment.description}</p>}
                    </button>
                    ))}
                </div>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={form} onSubmit={onSubmit}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col bg-white" autoComplete="off">
                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="paymentProfileId" className="inline-block text-sm mb-2">
                                    Seleccionar medio de pago
                                </label>
                                <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                    <Field
                                        as="select"
                                        id="paymentProfileId"
                                        name="paymentProfileId"
                                        placeholder="Seleccionar"
                                        className={`focus:ring-0 w-full appearance-none cursor-pointer rounded-lg outline-none py-2 px-4 border border-solid ${
                                            errors.paymentProfileId && touched.paymentProfileId ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'
                                        }`}
                                        disabled={loading}>
                                        <option disabled value="">
                                            Seleccionar
                                        </option>
                                        {paymentProfiles.map(profile => (
                                            <option key={profile.id} value={profile.id}>
                                                {`Cuenta **** ${String(profile.account_number).slice(-4)}`}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <ErrorMessage name="paymentProfileId" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                        </div>
                        <div className="mt-12 flex items-center justify-center gap-3">
                            <Button onClick={() => navigate(ROUTES.PAYMENTS)} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                                Cancelar
                            </Button>
                            <Button className={`${ selectedAmountPayments.value ? 'bg-primary-500' : 'bg-gray-500'} text-white text-sm px-10 py-2 rounded-md w-auto`} type="submit" disabled={!selectedAmountPayments.value} loading={loading}>
                                Aceptar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
