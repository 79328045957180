import http from "utils/http"

export class UserService {
    static async validateUser (phone) {
        try {
            const response = await http.post('/auth/validate-user', {
                phone,
            })

            return {
                success: true,
                data: response.data.data,
                code: response.data.code
            }
            
        } catch (error) {
            return {
                success: error.response.data.success,
                code: error.response.data.code,
                data: error.response.data.data,
                messages: error.response.data.messages
            }
        }
    }
}