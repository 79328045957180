import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import SuccessImg from 'assets/images/success.svg'

import Button from 'components/Button'
import RequestLoanContext from 'context/RequestLoan'
import { ROUTES } from 'routes'

export default function ApprovedLoanSuccess() {
    const { state: requestState, getLoan, saveState } = useContext(RequestLoanContext)
    const navigate = useNavigate()

    const onSubmit = () => {
        saveState({
            ...requestState,
            reviewingOffer: {
                completed: true
            }
        })

        navigate(ROUTES.LOAN_SUCCESS)
    }

    return (
        <div className="max-w-4xl py-10 m-auto">
            <div className="flex flex-col items-center justify-center text-center bg-white p-6 sm:p-12">
                <img src={SuccessImg} alt="Success" className="h-64 mb-6" />
                <h1 className="text-secondary-800 font-semibold sm:text-4xl text-4xl mb-2">Estamos procesando la solicitud</h1>
                <p className="max-w-3xl text-gray-700 text-lg">
                    Recibirás el contrato en tu correo electrónico <b>{requestState.user.email}</b>.<br/> Tu transferencia está siendo procesada y te comunicaremos la respuesta
                </p>
            </div>
        </div>
    )
}
