import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

/** Middlewares */
import AuthMiddleware from './middlewares/Auth'
import DashboardMiddleware from './middlewares/Dashboard'

/** Context Providers */
import AuthProvider from 'context/Auth/state'

/** Views */
import Login from 'views/Onboarding/Login'
import LoginCodeVerification from 'views/Onboarding/CodeVerification'
import RequestLoan from 'views/Onboarding'
import RequestLoanAmount from 'views/Onboarding/Amount'
import RequestLoanBusiness from 'views/Onboarding/Business'
import RequestLoanOwner from 'views/Onboarding/Owner'
import ApprovedLoan from 'views/Request'
import ApprovedLoanOffers from 'views/Request/Offers'
import ApprovedLoanPaymentProfile from 'views/Request/PaymentProfile'
import ApprovedLoanSummary from 'views/Request/Summary'
import ApprovedLoanSuccess from 'views/Request/Success'
import RequestLoanState from 'context/RequestLoan/state'
import Home from 'views/Active'
import Reviewing from 'views/Onboarding/Reviewing'
import Rejected from 'views/Active/Rejected'
import Loan from 'views/Active/Loan'
import Payments from 'views/Active/Payments'
import NewPayment from 'views/Active/NewPayment'
import PaymentProfiles from 'views/Active/PaymentProfiles'
import NewPaymentProfile from 'views/Active/NewPaymentProfile'

import Profile from 'views/Profile'
import ProfileUser from 'views/Profile/Owner'
import ProfileBusiness from 'views/Profile/Business'

import NotFound from 'views/NotFound'

export const ROUTES = {
    LOGIN: '/login',
    REQUEST_LOAN: '/registro',
    CODE_VERIFICATION: '/codigo-verificacion',

    BUSINESS: '/registro/negocio',
    OWNER: '/registro/informacion-general',
    REVIEWING: '/revisando',
    REJECTED: '/rechazado',

    OFFERS: '/prestamos/nuevo/ofertas',
    PAYMENT_PROFILE: '/prestamos/nuevo/perfil-pago',
    SUMMARY: '/prestamos/nuevo/resumen-prestamo',
    LOAN_SUCCESS: '/prestamos/nuevo/solicitud-enviada',

    ROOT: '/',
    BALANCE: '/prestamos/actual',
    PAYMENTS: '/prestamos/pagos',
    NEW_PAYMENT: '/prestamos/realizar-pago',
    PAYMENT_PROFILES: '/prestamos/cuenta-de-banco',
    NEW_PAYMENT_PROFILE: '/prestamos/nueva-cuenta-de-banco',

    PROFILE_USER: '/perfil/usuario',
    PROFILE_BUSINESS: '/perfil/negocio'
}

export default function kiwiRoutes() {
    return (
        <AuthProvider>
            <Routes>
                <Route element={<AuthMiddleware />}>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="login" element={<Login />} />
                    <Route exact path="registro" element={<RequestLoanAmount />} />
                    <Route exact path="codigo-verificacion" element={<LoginCodeVerification />} />
                    <Route
                        path="registro"
                        element={
                            <RequestLoanState>
                                <RequestLoan />
                            </RequestLoanState>
                        }>
                        <Route exact path="negocio" element={<RequestLoanBusiness />} />
                        <Route exact path="informacion-general" element={<RequestLoanOwner />} />
                    </Route>
                </Route>
                <Route
                    element={
                        <RequestLoanState>
                            <DashboardMiddleware />
                        </RequestLoanState>
                    }>
                    <Route exact path="revisando" element={<Reviewing />} />
                    <Route exact path="rechazado" element={<Rejected />} />
                    <Route exact path="perfil" element={<Profile />}>
                        <Route exact path="usuario" element={<ProfileUser />} />
                        <Route exact path="negocio" element={<ProfileBusiness />} />
                    </Route>
                    <Route path="prestamos" element={<Home />}>
                        <Route path="" element={<Navigate to="/prestamos/actual" />} />
                        <Route path="actual" element={<Loan />} />
                        <Route path="pagos" element={<Payments />} />
                        <Route path="realizar-pago" element={<NewPayment />} />
                        <Route path="cuenta-de-banco" element={<PaymentProfiles />} />
                        <Route path="nueva-cuenta-de-banco" element={<NewPaymentProfile />} />
                    </Route>
                    <Route path="prestamos/nuevo" element={<ApprovedLoan />}>
                        <Route exact path="ofertas" element={<ApprovedLoanOffers />} />
                        <Route exact path="perfil-pago" element={<ApprovedLoanPaymentProfile />} />
                        <Route exact path="resumen-prestamo" element={<ApprovedLoanSummary />} />
                        <Route exact path="solicitud-enviada" element={<ApprovedLoanSuccess />} />
                    </Route>
                </Route>
                
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AuthProvider>
    )
}
