import http from './http'

const createLinkToken = async () => {
    const response = await http.post('/plaid/token')
    return response
}

const exchangePlaidToken = async (public_token) => {
    const response = await http.post('/plaid/public-token-exchange', {
        public_token
    })
    return response
}

export { exchangePlaidToken, createLinkToken }
